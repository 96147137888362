/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import './theme.scss';
@import '../node_modules/@ag-grid-community/styles/ag-grid.css';
@import '../node_modules/@ag-grid-community/styles/ag-theme-balham.min.css';
@import '../node_modules/@ag-grid-community/styles/ag-theme-alpine.min.css';
@import '../node_modules/@ag-grid-community/styles/ag-theme-material.min.css';
@import 'highlight.js/styles/atom-one-dark.css';

* {
	letter-spacing: normal !important;
}

html,
body {
	margin: 0;
	height: 100%;
	background: var(--bkg-color);
	color: var(--primary-text);
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
	background: #000;
	opacity: 0.75 !important;
}

.w550 {
	font-weight: 550;
}

.lh40 {
	line-height: 40px;
}

*:focus {
	outline: none;
}

.material-symbols-outlined {
	font-variation-settings:
		'FILL' 0,
		'wght' 400,
		'GRAD' 0,
		'opsz' 24;
}

.material-symbols-outlined.filled {
	font-variation-settings: 'FILL' 1;
}

/////////////////////////////////
// CORPORATE DESIGN OVERWRITES //
/////////////////////////////////

a:not(.mdc-icon-button) {
	color: var(--color-cancom-links) !important;
}

// MAT BUTTONS
a.mat-mdc-raised-button,
button.mat-mdc-raised-button {
	background-color: transparent !important;
	color: var(--color-cancom-buttons) !important;
	white-space: nowrap;

	border: 2px solid var(--color-cancom-buttons);
	border-radius: 47px;
	opacity: 1;
}

a.mat-mdc-raised-button:hover,
button.mat-mdc-raised-button:hover {
	background-color: var(--color-cancom-buttons) !important;
	color: #fff !important;
}

// MAT BUTTONS WARN
a.mat-mdc-raised-button.mat-warn,
button.mat-mdc-raised-button.mat-warn {
	background-color: transparent !important;
	color: var(--color-cancom-warn) !important;

	border: 2px solid var(--color-cancom-warn);
	border-radius: 47px;
	opacity: 1;
}

a.mat-mdc-raised-button.mat-warn:hover,
button.mat-mdc-raised-button.mat-warn:hover {
	background-color: var(--color-cancom-warn) !important;
	color: #fff !important;
}

// MAT BUTTONS DISABLED
a.mat-mdc-raised-button:disabled,
button.mat-mdc-raised-button:disabled {
	background-color: transparent !important;
	border: 2px solid #c0c6d6;
	color: #c0c6d6 !important;
}

//////////////////////
// MDC DESIGN FIXES //
//////////////////////

.mat-mdc-button-touch-target {
	display: none;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
	padding: 24px !important;
}

mat-dialog-title {
	padding: 20px 24px 0px 24px;
}

button > .mat-icon {
	font-size: 24px !important;
	height: auto !important;
	width: auto !important;
	margin: 0 !important;
}

.mat-mdc-option {
	min-height: 42px !important;
}

.mat-mdc-form-field-infix {
	overflow: hidden;
}

.mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
	width: 100%;
}

.mat-mdc-form-field-hint-wrapper {
	padding: 0 8px !important;
}

.mat-mdc-card {
	border-radius: 0px !important;
}

.mat-mdc-snack-bar-action {
	color: rgba(255, 255, 255, 0.7) !important;
}

.mat-mdc-menu-panel.fullwidth-menu {
	max-width: none !important;
}

mat-form-field {
	> div.mdc-text-field {
		background-color: transparent !important;
	}
	> div.mat-mdc-text-field-wrapper {
		padding: 0 8px !important;
		> div.mat-mdc-form-field-focus-overlay {
			opacity: 0 !important;
		}
	}
}

.mat-mdc-card.mat-elevation-z2 {
	@include mat.elevation(2);
}

.mat-mdc-card.mat-elevation-z8 {
	@include mat.elevation(8);
}

.mat-mdc-form-field-hint {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

////////////////////
// CUSTOM CLASSES //
////////////////////

.properties-grid {
	--grid-column-count: 4;
	--grid-item--min-width: 350px;
	--gap-count: calc(var(--grid-column-count) - 1);
	--grid-item--max-width: calc(100% / var(--grid-column-count));

	display: grid;
	position: relative;
	grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));

	button {
		padding: 0 !important;
		height: 20px !important;
		width: 20px !important;
		display: inline-flex !important;
		align-items: center;
		justify-content: center;

		.mat-mdc-button-persistent-ripple,
		.mat-mdc-button-ripple {
			display: none !important;
		}

		> .mat-icon {
			font-size: 20px !important;
			height: auto !important;
			width: auto !important;
			margin: 0 !important;
		}
	}

	// TODO: This is a hack to make the grid items fill the height of the container.
	div:not(.mat-form-field-underline) {
		min-height: 20px;
	}

	> div {
		border-right: 1px solid #e0e0e0;
		padding: 1rem;

		> div:first-child {
			font-size: 18px;
			font-weight: bold;
			padding-bottom: 8px;
		}

		> div:last-child {
			font-size: 18px;
			overflow-wrap: anywhere;
		}
	}

	&::after {
		content: '';
		position: absolute;
		width: 5px;
		right: 0;
		top: 0;
		height: 100%;
		background: var(--bkg-color);
	}
}

.notificationbar-padding {
	padding: 16px;

	.mdc-snackbar__surface,
	.mdc-snackbar__label {
		padding: 0 !important;
	}
}

//////////////////////////////////
// TIMEPICKER MAT DIALOG COMPAT //
//////////////////////////////////

.timepicker-backdrop-overlay {
	z-index: 10000 !important;
}

.timepicker-overlay {
	z-index: 10000 !important;
}
//////////////////////
// CUSTOM SCROLLBAR //
//////////////////////

// ::-webkit-scrollbar {
// 	width: 8px;
// 	overflow-y: scroll;
// 	background: #313d49;
// 	box-shadow: inset 0 0 4px #707070;
// 	border-radius: 20px;
// }

// ::-webkit-scrollbar-thumb {
// 	background: #db0b2d;
// 	border-radius: 20px;
// }

////////////////////////////
// SERVICE LAYOUT CLASSES //
////////////////////////////

.action-row {
	display: flex;
	flex-direction: row;
	gap: 8px;
	min-height: 32px;
	margin-bottom: 8px;
	line-height: 32px;

	.action-title {
		font-size: 32px;
		font-weight: bold;
		line-height: 32px;
	}

	.action-field {
		line-height: 32px;
	}

	// .mat-mdc-raised-button {
	// 	border-radius: 20px;
	// 	padding-left: 16px;
	// 	padding-right: 16px;
	// }
}

.service-container {
	margin-top: 24px;
	overflow: hidden;
}

.service-header {
	font-size: 32px;
	line-height: 32px;
	font-weight: bold;
	margin-bottom: 16px;
}

.service-not-authorized {
	color: #da002d;
}

.service-info {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	max-width: 30%;
	word-break: break-word;
	gap: 16px;
	cursor: default;
	// flex: 1 1 100%;

	> div > div:first-of-type {
		font-size: 18px;
		font-weight: bold;

		display: flex;
		flex-direction: row;
		gap: 8px;
		place-content: center flex-start;
		align-items: center;

		mat-icon {
			cursor: pointer;
		}
	}
}

.service-content {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	flex: 1 1 0%;
	gap: 16px;
	padding: 0 8px;
	overflow-y: auto;
	padding-bottom: 8px;
	position: relative;
}

.disabled-icon {
	opacity: 0.5;
	cursor: not-allowed;
}

////////////////////////////////
// LOADING SKELETON ANIMATION //
////////////////////////////////

.pulse {
	animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
	// animation-delay: 0.5s;

	@keyframes pulse {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0.5;
		}
		100% {
			opacity: 1;
		}
	}
}

////////////////////////
// GENERAL ANIMATIONS //
////////////////////////

.spin {
	animation: spin 2s infinite linear;

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
}

.spin-reverse {
	animation: spin-reverse 2s infinite linear;

	@keyframes spin-reverse {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(-360deg);
		}
	}
}
